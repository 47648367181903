//定义变量
@c-mainColor: #0f7aff;
@c-mainText: #121212;
@c-advertColor: #223efb;
@c-borderColor: #eee;
@c-bgColor: #fafafa;
@c-whiteColor: #fff;
@c-titleColor: #000;
@c-iconColor: #29394a;
@media768: 16;
@media1024: (980 / 1440);
// 方法
.f-width() {
  width: 1440px;
}
//动画
.transition(@trans) {
  -webkit-transition: @trans;
  -moz-transition: @trans;
  -ms-transition: @trans;
  -o-transition: @trans;
  transition: @trans;
}
//放大
.transform(@trans) {
  -webkit-transform: @trans;
  -moz-transform: @trans;
  -ms-transform: @trans;
  -o-transform: @trans;
  transform: @trans;
  //transform：scale(2);
}
//省略号
.f-limitMore(@num) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
  // text-align: justify;
  word-break: break-word;
}
// 左右渐变
.LinearGradient(@sColor, @eColor) {
  background-color: @eColor;
  background: -webkit-linear-gradient(left, @sColor, @eColor);
  background: -o-linear-gradient(right, @sColor, @eColor);
  background: -moz-linear-gradient(right, @sColor, @eColor);
  background: linear-gradient(to right, @sColor, @eColor);
}
// 上下渐变
.TopBGradient(@sColor, @eColor) {
  background-color: @eColor;
  background: -webkit-linear-gradient(@sColor, @eColor);
  background: -o-linear-gradient(@sColor, @eColor);
  background: -moz-linear-gradient(@sColor, @eColor);
  background: linear-gradient(@sColor, @eColor);
}
//按钮动画效果
.f-btn(@color, @borderColor: @c-mainColor, @borderRadius: 0px) {
  //rgba(255, 255, 255, .3)
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 0;
    width: 0;
    background-color: @color;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    opacity: 0;
    .transition(0.15s all);
  }
  &:hover {
    border-color: lighten(@borderColor, 15%);
    &::before {
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: @borderRadius;
      border-color: @color;
      opacity: 1;
    }
  }
}

.scrollbar(@width, @borderRadius) {
  &::-webkit-scrollbar {
    width: @width;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
    border-radius: @borderRadius;
  }
  &::-webkit-scrollbar-thumb {
    background: #cebac4;
    border-radius: @borderRadius;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #cebac4;
    border-radius: @borderRadius;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #cebac4;
    border-radius: @borderRadius;
  }
}
