@import '../../styles/variables.less';

.m-Swiper-body {
  position: relative;
  z-index: 2;

  .ant-tabs {

    // padding-bottom: 30px;
    &.swiper-tab-card.swiper-tab-top {
      >.ant-tabs-nav {
        margin-bottom: 60px;

        &::before {
          border: none;
        }

        .ant-tabs-tab+.ant-tabs-tab {
          margin-left: 15px;
        }
      }

      .ant-tabs-nav-list {
        // justify-content: center;
        justify-content: space-around;
        width: 100%;

        .ant-tabs-tab {
          flex: 1;
          // min-width: 340px;
          justify-content: center;
          height: 100px;
          font-size: 24px;
          color: #41434c;

          &>div {
            width: 100%;

            &>div {
              min-width: 100%;
              padding: 10px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .ant-tabs-tab.ant-tabs-tab-active,
        .ant-tabs-tab:hover {
          background: #eefffb;
          border: 1px solid #00ffc0;
          box-shadow: 0px 10px 28px 2px rgba(133, 160, 193, 0.15);

          .ant-tabs-tab-btn {
            color: #000;
          }
        }
      }
    }

    &.swiper-tab-card.swiper-tab-left {
      .ant-tabs-nav-operations {
        display: none;
      }

      .ant-tabs-nav-list {
        justify-content: center;

        &>div {
          background: #ffffff;
          border: 1px solid #dadbe6;
          color: #121212;
          font-size: 30px;
          padding: 18px 30px 18px 18px;
          width: 550px;
          cursor: pointer;

          &:not(:first-of-type) {
            margin-top: 20px;
          }

          &>div {
            width: 100%;

            &>div {
              width: 100%;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;

              &>h3 {
                width: 100%;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                color: #41434c;
                font-size: 30px;
              }
            }
          }

          img,
          .img-box {
            width: 72px;
            height: 72px;
            margin-right: 20px;
          }

          &.ant-tabs-tab-active,
          &:hover {
            background: #eefffb;
            border: 1px solid #00ffc0;
            box-shadow: 0px 10px 28px 2px rgba(133, 160, 193, 0.15);

            .ant-tabs-tab-btn {
              color: #121212;
            }
          }
        }
      }
    }

    &.swiper-tab-line {
      >.ant-tabs-nav {
        margin-bottom: 60px;

        .ant-tabs-tab+.ant-tabs-tab {
          margin-left: 15px;
        }
      }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          justify-content: center;
          height: 80px;
          margin: 0 80px;
          padding: 0;
          font-size: 24px;
          color: #41434c;
          border-bottom: 4px solid transparent;

          &:hover {
            border-bottom-color: #28edf5;
          }
        }

        .ant-tabs-tab.ant-tabs-tab-active,
        .ant-tabs-tab:hover {
          border-bottom-color: #28edf5;

          .ant-tabs-tab-btn {
            color: #000;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
          height: 4px;
          background: #28edf5;
        }
      }
    }
  }

  .adm-tabs {
    display: none;

    .adm-tabs-header {
      border-bottom-color: transparent;

      .adm-tabs-header-mask {
        display: none;
      }
    }
  }

  .adm-swiper-slide {
    height: auto;
  }

  .tab-body {
    display: flex;
    align-items: center;

    .u-tab-left {
      flex: 0 0 480px;
      margin-right: 60px;

      >img,
      .img-box {
        display: block;
        width: 100%;
        height: 480px;
        object-fit: cover;
      }
    }

    .u-tab-right {
      flex: auto;

      >h3 {
        display: block;
        margin-bottom: 20px;
        color: #000;
        font-size: 48px;
        line-height: 160%;
        font-weight: bold;
      }

      .u-content {
        font-size: 24px;
        color: #41434c;
        line-height: 180%;
      }
    }
  }

  .adm-swiper {
    padding-bottom: 30px;

    .adm-swiper-indicator {
      display: none;
    }

    &.m-swiper-one .adm-swiper-track-inner {
      transform: none !important;
    }
  }

  .list-body {
    padding: 0 7%;

    .u-tab-imgBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 100px;
      background-color: #fff;

      &.item-li {
        flex: auto !important;
      }

      .u-left {
        flex: 0 0 362px;

        img,
        .img-box {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .u-right {
        padding-left: 60px;
        color: #41434c;

        p {
          margin-bottom: 0;
          font-size: 24px;
          line-height: 160%;
        }
      }
    }

    .u-tab-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      background: #fff;

      .item-li {
        flex: 0 0 25%;
        padding: 60px 0;
        text-align: center;
        background-color: #fff;

        img,
        .img-box {
          display: block;
          margin: 0 auto;
          width: 160px;
          height: 160px;
        }

        h4 {
          display: block;
          font-weight: 100;
          font-size: 24px;
          color: #000000;
          margin-top: 20px;
          word-break: break-word;
        }
      }
    }
  }
}

@media1440: 0.75;
@media screen and (max-width: 1440px) {
  .m-Swiper-body {
    .ant-tabs {

      // padding-bottom: 30px;
      &.swiper-tab-card.swiper-tab-top {
        >.ant-tabs-nav {
          margin-bottom: (60px * @media1440);

          .ant-tabs-tab+.ant-tabs-tab {
            margin-left: (15px * @media1440);
          }
        }

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            height: (100px * @media1440);
            font-size: (24px * @media1440);

            h3 {
              font-size: 24px * @media1440;
            }
          }
        }
      }

      &.swiper-tab-card.swiper-tab-left {
        .ant-tabs-nav-list {
          &>div {
            font-size: 30px * @media1024;
            padding: 18px * @media1024 30px * @media1024 18px * @media1024 18px * @media1024;
            cursor: pointer;
            width: 550px * @media1024;

            h3 {
              font-size: 30px * @media1024;
            }

            img,
            .img-box {
              width: 72px * @media1024;
              height: 72px * @media1024;
              margin-right: 20px * @media1024;
            }
          }
        }
      }

      &.swiper-tab-line {
        >.ant-tabs-nav {
          margin-bottom: (60px * @media1440);

          .ant-tabs-tab+.ant-tabs-tab {
            margin-left: (15px * @media1440);
          }
        }

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            height: (80px * @media1440);
            font-size: (24px * @media1440);
            margin: 0 80px * @media1440;
          }
        }
      }
    }

    .tab-body {
      .u-tab-left {
        flex: 0 0 (480px * @media1440);
        margin-right: (60px * @media1440);

        >img,
        .img-box {
          height: (480px * @media1440);
        }
      }

      .u-tab-right {
        >h3 {
          font-size: (48px * @media1440);
        }

        .u-content {
          font-size: (24px * @media1440);
        }
      }
    }

    .list-body {
      .u-tab-imgBox {
        padding: (60px * @media1440) (100px * @media1440);

        .u-left {
          flex: 0 0 (362px * @media1440);
        }

        .u-right {
          padding-left: 0;

          p {
            font-size: (24px * @media1440);
          }
        }
      }

      .u-tab-list {
        .item-li {
          padding: (60px * @media1440) 0;

          img,
          .img-box {
            width: (160px * @media1440);
            height: (160px * @media1440);
          }

          h4 {
            font-size: (24px * @media1440);
            margin-top: (20px * @media1440);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .m-Swiper-body {
    .ant-tabs {
      display: none;
    }

    .tab-body {
      display: block;
      padding: 20px 30px;
      box-shadow: 0 0 15px #f1f1f1;

      .u-tab-left {
        width: 100%;
        margin-right: 0;

        >img,
        .img-box {
          height: auto;
        }
      }

      .u-tab-right {
        >h3 {
          margin: 20px 0;
          font-size: (40rem / @media768);
        }

        .u-content {
          line-height: 160%;
        }
      }
    }

    .adm-tabs {
      display: block;
      --fixed-active-line-width: 6.25rem;
      --active-title-color: #121212;
      --active-line-color: #28edf5;

      // --active-line-height: 4px;
      .adm-tabs-tab {
        font-size: 24rem / @media768;
      }
    }

    .adm-swiper-item {
      padding: 15px;
    }

    .list-body {
      padding: 0 20px;

      .u-tab-imgBox {
        padding: 30px 60px;

        .u-left {
          flex: 0 0 36%;
        }

        .u-right {
          padding-left: 6%;

          p {
            font-size: 16px;
          }
        }
      }

      .u-tab-list {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px;

        .item-li {
          // max-width: 162px;
          flex: 0 0 calc(~'25% - 20px');
          margin-right: 20px;
          margin-top: 20px;
          box-shadow: 0 0 15px #f5f5f5;

          &:nth-of-type(4n) {
            margin-right: 0;
          }

          padding: 30px 0;

          img,
          .img-box {
            width: 72%;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .m-Swiper-body {
    .ant-tabs.swiper-tab-line .ant-tabs-nav-list .ant-tabs-tab {
      font-size: 14px;
    }

    .ant-tabs.swiper-tab-line>.ant-tabs-nav {
      margin-bottom: 10px;
    }

    .list-body {
      .u-tab-list .item-li {
        flex: 0 0 calc(~'33% - 15px');
        margin-right: 15px;
        margin-top: 15px;
        padding: 20px 0;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        &:nth-of-type(4n) {
          margin-right: 15px;
        }

        h4 {
          margin-top: 10px;
        }
      }

      .u-tab-imgBox {
        padding: 20px 40px;

        .u-left {
          flex: 0 0 30%;
        }

        .u-right p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .m-Swiper-body {
    .tab-body {
      .u-tab-right {
        >h3 {
          font-size: 28px;
        }

        .u-content {
          font-size: 14px;
        }
      }
    }

    .list-body {
      .u-tab-list {
        justify-content: space-between;

        .item-li {
          flex: 0 0 calc(~'50% - 15px');
          margin-right: 0;
          margin-bottom: 20px;

          &:nth-of-type(4n) {
            margin-right: 0;
          }
        }
      }

      .u-tab-imgBox {
        display: block;
        padding: 30px 40px;

        .u-left {
          width: 80%;
          margin: 0 auto;
        }

        .u-right {
          padding-left: 0;
          margin-top: 20px;
        }
      }
    }

    .adm-swiper-item {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 380px) {
  .m-Swiper-body {
    .tab-body {
      padding: 20px;

      .u-tab-left {
        width: 100%;
        margin-right: 0;

        >img,
        .img-box {
          height: auto;
        }
      }

      .u-tab-right {
        >h3 {
          margin: 15px 0;
          font-size: 20px;
        }
      }
    }
  }
}