@import '../../styles/variables.less';
.m-index-bottom {
  background: url('../../static/images/index-content-bg2.jpg') no-repeat;
  background-size: contain;
  background-position: center bottom;
  .bottom-list {
    display: flex;
    justify-content: center;
    padding-bottom: 120px;
    & > label {
      width: 340px;
      height: 80px;
      border-radius: 5px;
      display: inline-block;
      text-align: center;
      line-height: 80px;
      font-size: 24px;
      color: #0d3078;
      border: none;
      position: relative;
      background: #fff;
      &:hover {
        background: @c-advertColor;
        color: #fff;
      }
      .f-btn(rgba(255, 255, 255, 0.3));
      img, .img-box {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        display: inline-block;
        border-radius: 4px;
        background-color: #fff;
        line-height: normal;
      }
      &:not(:first-of-type) {
        margin-left: 40px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .m-index-bottom {
    .bottom-list {
      padding-bottom: 120px * @media1024;
      & > label {
        width: 340px * @media1024;
        height: 80px * @media1024;
        line-height: 80px * @media1024;
        font-size: 24px * @media1024;
        img, .img-box {
          width: 30px * @media1024;
          height: 30px * @media1024;
        }
        &:not(:first-of-type) {
          margin-left: 40px * @media1024;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .m-index-bottom {
    background: url('../../static/images/about-bottom-bg-mobile.jpg') no-repeat;
    background-size: contain;
    background-position: center bottom;
    position: relative;
    .bottom-list {
      flex-direction: column;
      padding-bottom: 120rem / @media768;
      & > label {
        width: 100%;
        height: 80rem / @media768;
        line-height: 80rem / @media768;
        font-size: 24rem / @media768;
        img, .img-box {
          width: 30rem / @media768;
          height: 30rem / @media768;
        }
        &:not(:first-of-type) {
          margin-left: 0rem / @media768;
          margin-top: 30rem / @media768;
        }
      }
    }
  }
}
