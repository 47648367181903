@import '../../styles/variables.less';

.m-banner {
  height: auto;
  z-index: 1;

  .slick-list {
    height: 100%;
  }

  .slick-slide>div>a,
  .slick-slide>div>div {
    display: block;
    max-height: 100%;
    position: relative;

    img,
    .img-box {
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: cover;

      &.m-banner-mobile {
        display: none;
      }
    }

    .m-banner-btn {
      position: absolute;
      bottom: 10%;
      left: 12.5%;

      &>label {
        width: 340px;
        height: 80px;
        border-radius: 5px;
        box-shadow: 0px 10px 17px 3px rgba(6, 2, 104, 0.05);
        // display: inline-flex;
        // justify-content: center;
        // align-items: center;
        display: inline-block;
        text-align: center;
        line-height: 80px;
        font-size: 22px;
        color: #0d3078;
        border: none;
        position: relative;
        background: #ffffff;

        img,
        .img-box {
          border-radius: 4px;
          width: 30px;
          height: 30px;
          margin: 0 10px 0 0;
          display: inline-block;
          vertical-align: middle;
          background-color: #fff;
          line-height: normal;
        }

        &:not(:first-of-type) {
          margin-left: 40px;
        }

        &:hover {
          background: @c-advertColor;
          color: #fff;
        }

        .f-btn(rgba(255, 255, 255, 0.3), @borderRadius: 5px);
      }
    }
  }

  .slick-dots {
    top: initial;
    bottom: 30%;
    left: 12.5%;
    width: 15%;
    height: 8px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin: 0px;

    li,
    li button {
      flex: 1;
      border-radius: 0;
      margin: 0;
      height: 100%;
      text-align: left;

      &::before {
        display: none;
      }
    }

    .slick-active {
      background-color: @c-whiteColor;
    }
  }
}

@media screen and (max-width: 1440px) {
  .m-banner {

    .slick-slide>div>a,
    .slick-slide>div>div {
      .m-banner-btn {
        &>label {
          width: 340px * @media1024;
          height: 80px * @media1024;
          line-height: 80px * @media1024;
          font-size: 22px * @media1024;

          img,
          .img-box {
            width: 30px * @media1024;
            height: 30px * @media1024;
          }

          &:not(:first-of-type) {
            margin-left: 40px * @media1024;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .m-banner {
    width: 768rem / @media768;
    height: 1108rem / @media768;

    .slick-slide>div>a,
    .slick-slide>div>div {

      // img {
      //   display: none;
      // }
      .img-box {
        display: none;

        &.m-banner-mobile {
          display: block;
          width: 768rem / @media768;
          // img {
          //   display: block;
          // }
        }
      }

      .m-banner-btn {
        bottom: 10%;
        left: 0%;
        width: 100%;
        padding: 0 50rem / @media768;

        &>label {
          width: 100%;
          height: 90rem / @media768;
          display: block;
          line-height: 90rem / @media768;
          font-size: 24rem / @media768;

          img,
          .img-box {
            width: 30rem / @media768;
            height: 30rem / @media768;
          }

          &:first-of-type {
            .f-btn(rgba(0, 0, 0, 0.1));
          }

          &:not(:first-of-type) {
            margin-left: 0px;
            margin-top: 30rem / @media768;
          }
        }
      }
    }

    .slick-dots {
      top: initial;
      bottom: 0%;
      left: 0;
      left: 0%;
      width: 100%;
      -webkit-transform: translate(0%, 0%);
      transform: translate(0%, 0%);
      padding-left: 0;
      height: 8px;

      li,
      li button {
        flex: 1;
      }

      .slick-active {
        background-color: @c-advertColor;

        button {
          background: @c-advertColor !important;
        }
      }
    }
  }
}